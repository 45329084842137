import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {},
  thumb: {
    h: "1",
    w: "1",
    bgColor: "primary",
    _checked: {
      transform: "translateX(14px)",
      bgColor: "white",
    },
  },
  track: {
    h: "1",
    w: "2",
    bgColor: "gray.300",
    _checked: {
      bgColor: "primary",
    },
  },
});

const switchTheme = defineMultiStyleConfig({ baseStyle });
export default switchTheme;
