const sizes = {
  "0.1": "0.1rem",
  "0.5": "0.5rem",
  "0.7": "0.7rem",
  "0.9": "0.9rem",
  "1": "1rem",
  "1.5": "1.5rem",
  "2": "2rem",
  "2.5": "2.5rem",
  "3": "3rem",
  "3.5": "3.5rem",
  "4": "4rem",
  "5": "5rem",
  "5.5": "7rem",
  "6": "10rem",
  "7": "11rem",
  "8": "12rem",
  "9": "16rem",
  "10": "19rem",
  "11": "20rem",
  "12": "21rem",
  "13": "23rem",
  "14": "24rem",
  "15": "25rem",
  "16": "29rem",
  "17": "32rem",
  "18": "37rem",
  "19": "42rem",
  "20": "47rem",
  "21": "72rem",
  "22": "75rem",
  "23": "65rem",
  "24": "50rem",
  number1: "7rem",
  number2: "13rem",
  number3: "6rem",
  number4: "0.3125rem",
  number5: "56rem",
  number6: "12.8rem",
};

export default sizes;
