import { extendTheme } from "@chakra-ui/react";

import styles from "theme/styles";
import Button from "theme/uiKit/inputs/button";
import typography from "theme/foundations/typography";
import Text from "theme/uiKit/display/text";
import colors from "theme/foundations/colors";
import borders from "theme/foundations/borders";
import radius from "theme/foundations/radius";
import sizes from "theme/foundations/sizes";
import { spacing } from "theme/foundations/spacing";
import switchTheme from "theme/foundations/Switch";
import { closeButtonTheme } from "theme/foundations/closeButton";
import { Input, PinInput } from "theme/uiKit/inputs/inputs";

const overrides = {
  styles,
  typography,
  colors,
  borders,
  radii: radius,
  sizes,
  space: spacing,
  components: {
    Button,
    Text,
    Switch: switchTheme,
    CloseButton: closeButtonTheme,
    Input: Input,
    PinInput: PinInput,
  },
};
const Theme = extendTheme(overrides);

export default Theme;
