import { ComponentStyleConfig } from "@chakra-ui/react";

const defaultInputsStyle = {
  p: "5",
  h: "2.5",
  bg: "white",
  borderRadius: "radius2",
  borderStyle: "solid",
  borderWidth: "0.1rem",
  borderColor: "gray.200",
  _placeholder: { color: "black.50" },
  _invalid: { borderColor: "red.500", bg: "red.50" },
  _focus: { borderColor: "primary" },
  _active: { borderColor: "primary", bg: "black.50" },
};

export const Input: ComponentStyleConfig = {
  variants: {
    default: {
      field: {
        ...defaultInputsStyle,
      },
    },
  },
  defaultProps: {
    size: "lg",
    variant: "default",
  },
};

export const Textarea: ComponentStyleConfig = {
  variants: {
    default: {
      ...defaultInputsStyle,
    },
  },
  defaultProps: {
    size: "lg",
    variant: "default",
  },
};

export const PinInput: ComponentStyleConfig = {
  defaultProps: {
    size: {
      md: {
        width: "3.5",
        height: "3.5",
      },
    },
  },
};
