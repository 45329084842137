import { api } from "./index";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listApiV1CoreOrganizationPayout: build.query<
      ListApiV1CoreOrganizationPayoutApiResponse,
      ListApiV1CoreOrganizationPayoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payout/`,
        params: { ordering: queryArg.ordering, page: queryArg.page },
      }),
    }),
    retrieveApiV1CoreOrganizationPayout: build.query<
      RetrieveApiV1CoreOrganizationPayoutApiResponse,
      RetrieveApiV1CoreOrganizationPayoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payout/${queryArg.id}/`,
      }),
    }),
    postApiV1CoreOrganizationPayout: build.mutation<
      RetrieveApiV1CoreOrganizationPayoutApiResponse,
      PostApiV1CoreOrganizationPayoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payout_request/`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as payout };
export type ListApiV1CoreOrganizationPayoutApiResponse =
  /** status 200  */ PaginatedPayoutList;
export type ListApiV1CoreOrganizationPayoutApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  organizationPk: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type RetrieveApiV1CoreOrganizationPayoutApiResponse =
  /** status 200  */ Payout;
export type RetrieveApiV1CoreOrganizationPayoutApiArg = {
  id: string;
  organizationPk: string;
};

export type PostApiV1CoreOrganizationPayoutApiArg = {
  organizationPk: string;
};

export type PayoutStatusEnum = "success" | "pending";
export type Payout = {
  id: string;
  amount: number;
  commission: string;
  reference: string;
  created: string;
  updated: string;
  status: PayoutStatusEnum;
  payments_count: number;
};
export type PaginatedPayoutList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Payout[];
};
export const {
  useListApiV1CoreOrganizationPayoutQuery,
  useRetrieveApiV1CoreOrganizationPayoutQuery,
  usePostApiV1CoreOrganizationPayoutMutation,
} = injectedRtkApi;
