import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/index";

import { useEffect, useState } from "react";
import { ELEMENT_PER_PAGE } from "constants/index";
import { formatDate, listSelectOptions } from "utils/helpers";
import SearchFilter from "components/Filters/SearchFilter";
import SelectFilter from "components/Filters/SelectFilter";
import {
  PayoutStatusEnum,
  useListApiV1CoreOrganizationPayoutQuery,
} from "store/api/organization";
import { usePostApiV1CoreOrganizationPayoutMutation } from "store/api/payout";
import { toast } from "react-toastify";
import { notify } from "store/slices/NotifyToastSlice";

const payoutStatus: PayoutStatusEnum[] = ["pending", "success"];

const columns: ColumnsType<any> = [
  {
    title: "Reference",
    dataIndex: "reference",
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => {
      if (value == "failed") {
        return <Text color="stateError">{value}</Text>;
      }
      return <Text color="stateSuccess">{value}</Text>;
    },
  },
  {
    title: "Transaction Link",
    dataIndex: "id",
    render: (value) => {
      return (
        <Link color="primary" textDecoration="underline">
          {value}
        </Link>
      );
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: true,
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Payout Date",
    dataIndex: "created",
    render: (value) => <Text>{formatDate(value)}</Text>,
  },
];

const Payouts = () => {
  const organization = useSelector(
    (state: RootState) => state.organization.currentOrganization
  );
  const organizationId = !!organization ? organization.id : "";
  const [queryParams, setQueryParams] = useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [paymentRequest, { isLoading }] =
    usePostApiV1CoreOrganizationPayoutMutation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const { data: payouts, isFetching: isFetchingPayouts } =
    useListApiV1CoreOrganizationPayoutQuery({
      organizationPk: organizationId,
      page: page,
      ...queryParams,
    });
  const [statusFilterValue, setStatusFilterValue] = useState<string | null>(
    null
  );

  const handleAddQueryParam = (param: any, value: any) => {
    const queryParam: any = {};
    queryParam[param] = value;
    setQueryParams({ ...queryParams, ...queryParam });
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);

    window.scrollTo({ top: 70, behavior: "smooth" });
  };

  const handlePaymentRequest = async () => {
    try {
      await paymentRequest({
        organizationPk: organizationId,
      }).unwrap();
      dispatch(
        notify({
          message: "The withdrawal request has been processed.",
          typeToast: toast.TYPE.SUCCESS,
        })
      );
      onClose();
    } catch (error) {
      dispatch(
        notify({
          message: "Withdrawal request failed. Please contact Transfa support",
          typeToast: toast.TYPE.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    if (statusFilterValue) {
      handleAddQueryParam("status", statusFilterValue);
    } else {
      setQueryParams({});
    }
  }, [statusFilterValue]);

  return (
    <Box w="full">
      <Box marginY="24px">
        <Text variant="subTitleBold">Payouts</Text>
      </Box>
      <Flex mb="3" justifyContent="space-between">
        <Flex>
          <SearchFilter handleSearch={handleAddQueryParam} />
          <Box mx="2">
            <SelectFilter
              placeholder="Filter by status"
              options={listSelectOptions(payoutStatus)}
              value={statusFilterValue}
              handleFiltering={setStatusFilterValue}
            />
          </Box>
        </Flex>

        <Box>
          <Button isLoading={isLoading} onClick={onOpen}>
            Withdraw request
          </Button>
        </Box>
      </Flex>
      <PaymentConfirmationModal
        onSubmit={handlePaymentRequest}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text variant="textBody">
          By confirming the withdrawal request, you will receive the funds at
          the following number <b>{organization?.phone}</b> within a period of 1
          hour to 3 days.
        </Text>

        <Text mt="1rem" variant="textSmall" color="primary">
          If this phone number is incorrect, please contact Transfa support.
        </Text>
      </PaymentConfirmationModal>
      <Table
        columns={columns}
        dataSource={payouts?.results}
        loading={isFetchingPayouts}
        pagination={{
          onChange: handlePageChange,
          current: page,
          total: payouts?.count,
          pageSize: ELEMENT_PER_PAGE,
          showSizeChanger: false,
        }}
      />
    </Box>
  );
};

const PaymentConfirmationModal = (
  props: ModalProps & { onSubmit: () => void }
) => {
  return (
    <Modal {...props} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" marginTop="50px" color="primary">
          <Text variant="subTitle">Confirm withdraw </Text>
        </ModalHeader>
        <ModalCloseButton bg="stateError" borderRadius="50px" color="white" />
        <ModalBody mt="20px" pb="10px">
          {props.children}
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={props.onSubmit}
            mb="20px"
            w="full"
            colorScheme="primary"
            isLoading={false}
          >
            Make withdraw request
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default Payouts;
