const radius = {
  radius1: "0.125rem",
  radius2: "0.25rem",
  radius3: "0.375rem",
  radius4: "0.5rem",
  radius5: "0.625rem",
  radius6: "0.75rem",
  radius7: "1rem",
  radius8: "1.25rem",
  radius9: "1.5rem",
  radius10: "3rem",
};

export default radius;
