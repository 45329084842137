import { Flex, Text } from "@chakra-ui/react";

interface StatsCardType {
  color: string;
  value: string | number;
  description: string;
}

const StatsCard = ({
  color = "success2",
  value,
  description,
}: StatsCardType) => {
  return (
    <Flex
      borderRadius="radius7"
      w="full"
      bg={color}
      minHeight="9"
      direction="column"
      alignItems="center"
      justifyContent="center"
      border="0.05"
      borderColor="primary"
    >
      <Text marginBottom="7" variant="title">
        {value}
      </Text>
      <Text variant="subTitle" color="gray.500">
        {description}
      </Text>
    </Flex>
  );
};
export default StatsCard;
