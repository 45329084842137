import {
  DASHBOARD_HOME,
  DASHBOARD_PAYMENTS,
  DASHBOARD_DEVELOPERS,
  DASHBOARD_CONTACTS_US,
  DASHBOARD_PAYOUTS,
} from "constants/routing";
import {
  HouseFill,
  ArrowLeftRight,
  CodeSlash,
  EnvelopeFill,
  Wallet2,
  Icon,
} from "react-bootstrap-icons";

type MenuListType = {
  label: string;
  icon: Icon;
  link: string;
  key: string;
};
export const menuList: MenuListType[] = [
  {
    label: "Home",
    icon: HouseFill,
    link: DASHBOARD_HOME,
    key: "1",
  },
  {
    label: "Payments",
    icon: ArrowLeftRight,
    link: DASHBOARD_PAYMENTS,
    key: "2",
  },
  {
    label: "Payouts",
    icon: Wallet2,
    link: DASHBOARD_PAYOUTS,
    key: "3",
  },
  {
    label: "Developers",
    icon: CodeSlash,
    link: DASHBOARD_DEVELOPERS,
    key: "4",
  },
  {
    label: "Contact us",
    icon: EnvelopeFill,
    link: DASHBOARD_CONTACTS_US,
    key: "5",
  },
];
