import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { menuList } from "constants/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/transfa_logo_long.png";
import { DASHBOARD_HOME } from "constants/routing";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box bg="lightGray" minH="100vh" w="full">
      <Flex
        pt="9"
        cursor="pointer"
        onClick={() => navigate(DASHBOARD_HOME)}
        flexDirection="row"
        justifyContent="center"
      >
        <Image w="55%" src={Logo} />
      </Flex>
      <Box height="100%" marginTop="10" px="4">
        {menuList.map(({ link, label, icon: Icon }, index) => (
          <Flex
            w="full"
            height="3.5"
            key={index}
            bg={location.pathname == link ? "white" : ""}
            color={location.pathname == link ? "primary" : "gray.400"}
            justifyContent="center"
            alignItems="center"
            onClick={() => navigate(link)}
            cursor="pointer"
            borderRadius="radius5"
          >
            <Box w="2.5">
              <Icon size={18} />
            </Box>
            <Box w="5.5">
              <Text
                color={location.pathname == link ? "primary" : "gray.400"}
                variant="textBody"
                fontWeight="500"
              >
                {label}
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default SideBar;
