const negativeSpacing = {
  "-1": "-0.125rem",
  "-2": "-0.25rem",
  "-3": "-0.5rem",
  "-4": "-0.75rem",
  "-4.1": "-0.85rem",
  "-5": "-1rem",
  "-7": "-2rem",
  "-9": "-3rem",
  "-11": "-5rem",
  "-12": "-6rem",
  "-13": "-10rem",
};

export const spacing = {
  "-8.1": "-2.8125rem",
  "1": "0.125rem",
  "2": "0.25rem",
  "2.1": "0.3125rem",
  "2.2": "0.35rem",
  "3": "0.5rem",
  "4": "0.75rem",
  "5": "1rem",
  "6": "1.5rem",
  "7": "2rem",
  "8": "2.5rem",
  "8.1": "2.8125rem",
  "9": "3rem",
  "10": "4rem",
  "11": "5rem",
  "12": "6rem",
  "13": "10rem",
  "14": "17rem",
  "15": "40%",
  "17": "40%",
  "18": "8rem",
  ...negativeSpacing,
};
