import { useEffect } from "react";
import { useField } from "formik";
import { InputFieldPropsDumbType } from "uikit/inputs/dumb/InputField";
import OtpInputDumb from "uikit/inputs/dumb/OtpInputField"

type OtpFieldPropsType = InputFieldPropsDumbType & {
  label?: string;
  placeholder?: string;
  name: string;
  defaultValue?: string;
  partlyControlledValue?: string;
  validate?: (_: any) => undefined | string | Promise<any>;
  validateOnChange?: boolean;
  variantTextLabel?: string;
  pinNumber?: number;
};

const OtpInput = ({
  label,
  validateOnChange = true,
  size: _,
  defaultValue,
  partlyControlledValue,
  variantTextLabel,
  pinNumber = 6,
  ...props
}: OtpFieldPropsType) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField({
    name: props.name,
    validate: (value: string) => {
      if (value?.length < pinNumber - 1) {
        return "Le code pin est requis";
      }
      return "";
    },
  });
  const onChange = (val: string) => {
    setValue(val, validateOnChange);
    setTouched(true, props.required);
  };
  useEffect(() => {
    //for case defaultValue is set
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const hasError = !!error && touched;
  return (
    <OtpInputDumb
      {...field}
      name={field.name}
      defaultValue={defaultValue}
      placeholder={props.placeholder}
      onChange={onChange}
      label={label}
      hasError={hasError}
      error={error}
      variantTextLabel={variantTextLabel}
    />
  );
};
export default OtpInput;
