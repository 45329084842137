import { Box, Flex } from "@chakra-ui/react";
import {
  PAGE_NOT_FOUND,
  ROUTES_ALL,
  ROUTE_WITH_NO_SIDEBAR,
} from "constants/routing";
import { useLocation, Navigate } from "react-router-dom";
import SideBar from "./Dashboard/SideBar";
import ProfileMenu from "./ProfileMenuDropdown";
import ChatwootWidget from "./ChatWoot";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { spacing } from "theme/foundations/spacing";

interface LayoutType {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutType) => {
  const location = useLocation();
  const user = useSelector((state: RootState) => state.auth.user);

  if (!ROUTES_ALL.includes(location.pathname)) {
    return <Navigate to={PAGE_NOT_FOUND} />;
  }
  if (ROUTE_WITH_NO_SIDEBAR.includes(location.pathname)) {
    return <Flex w="full">{children}</Flex>;
  }
  return (
    <Flex w="full">
      <Box w="9" position="fixed">
        <SideBar />
      </Box>
      <Flex
        w={`calc( 100% - ${spacing[10]} )`}
        marginLeft="20%"
        padding="1%"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="end">
          <ProfileMenu />
        </Box>
        {children}
        {!!user && <ChatwootWidget user={user} />}
      </Flex>
    </Flex>
  );
};
export default Layout;
