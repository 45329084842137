import * as Yup from "yup";

const passwordRegex =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$";

const passwordSchema = Yup.string()
  .required("The password is required")
  .matches(
    new RegExp(passwordRegex),
    "Le mot de passe doit contenir au moins 8 caractères une lettre majuscule, une lettre miniscule, un chiffre et un des caractères spéciaux suivant @$!%*?&"
  );

export const loginValidation = Yup.object({
  username: Yup.string().trim().required("The email is required."),
  password: Yup.string().required("The password is required"),
});

export const RegisterOwnerStepValidation = Yup.object({
  first_name: Yup.string().trim().required("The first name is required."),
  last_name: Yup.string().trim().required("The last name is required."),
  email: Yup.string().email().trim().required("The email is not valid"),
  password: passwordSchema,
  phone: Yup.string().trim().length(8).required("The phone is not valid"),
});

export const RegisterOrganisationStepValidation = Yup.object({
  organization: Yup.object({
    name: Yup.string().trim().required("The organisation name is required."),
    email: Yup.string().email().trim().required("The email is not valid"),
    phone: Yup.string().trim().required("The phone is not valid"),
  }),
});

export const ForgotPasswordMailStepValidation = Yup.object({
  email: Yup.string().email().trim().required("The email is not valid"),
});

export const otpStepValidation = Yup.object({
  code: Yup.string().trim().required("The otp code is required."),
});

export const ForgotPasswordUpdatePasswordValidation = Yup.object({
  new_password: passwordSchema,
  confirm_new_password: Yup.string().oneOf(
    [Yup.ref("new_password"), null],
    "Passwords must match"
  ),
  name: Yup.string().trim().required("The organisation name is required."),
  email: Yup.string().email().trim().required("The email is not valid"),
  phone: Yup.string().trim().length(8).required("The phone is not valid"),
  city: Yup.string().email().trim().required("The city is not valid"),
  country: Yup.string().trim().required("The country is not valid"),
});
