import { ComponentStyleConfig } from "@chakra-ui/react";

export const closeButtonTheme: ComponentStyleConfig = {
  baseStyle: {
    w: "2",
    h: "2",
    m: "4",
    color: "white",
  },
};
