export type SubjectType = {
  hash: string;
  description: string;
};

export const subjectList: SubjectType[] = [
  {
    hash: "1",
    description: "Payout qui ne passe pas",
  },
  {
    hash: "2",
    description: "Paiement avec soucis",
  },
  {
    hash: "3",
    description: "Souci pour changer de mot de passe",
  },
  {
    hash: "4",
    description: "Vérification",
  },
];
