const solidButtonVariants = {
  solid: {
    color: "white",
    bg: "primary",
    _hover: {
      bg: "primary3",
      textDecoration: "none",
      _disabled: {
        bg: "primary",
      },
    },
  },
};
export default solidButtonVariants;
